import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './AboutVision.module.css';
import GraphImage from './Graph.png';
import AuthorImage from './me.jpeg';
import HeroImage from './0_10.png';

export default function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About Us - Financial-Portfolio.io</title>
        <meta name="description" content="Learn how Financial-Portfolio.io addresses content kleptocracy with blockchain, ensuring fair compensation, transparent usage tracking, and equitable revenue sharing for financial media providers." />
      </Helmet>
      <main className={styles.main}>

      <h1 className={styles.h1}>About Us</h1>
        
        <nav className={styles.tableOfContents}>
          <ul>
            <li><a href="#mission">Our Mission</a></li>
            <li><a href="#approach">Our Approach</a></li>
            <li><a href="#research">Latest Research</a></li>
          </ul>
        </nav>

        <div className={styles.heroImageContainer}>
          <img src={HeroImage} alt="Abstract representation of AI and data" className={styles.heroImage} />
        </div>

        <section id="mission" className={styles.section}>
          <h2 className={styles.h2}>Our Mission</h2>
          <p className={styles.p}>
            Infocom, through it's flagship product Financial-Portfolio.io addresses a crucial challenge in the financial news industry: the unmonitored and uncompensated use of content, often referred to as "content kleptocracy". By applying blockchain technology, we provide a secure and transparent platform that allows financial media providers to retain control over their work and ensures fair, measurable compensation.
          </p>
          <p className={styles.p}>
            Our mission is to create an environment where financial content can be responsibly accessed and valued. Through decentralized technology, Financial-Portfolio.io supports ethical content distribution, creating a balanced system that benefits content creators, researchers, and investors alike.
          </p>
        </section>

        <section id="approach" className={styles.section}>
          <h2 className={styles.h2}>Our Approach</h2>
          <p className={styles.p}>
            Blockchain ensures that each piece of content is timestamped and verifiable, allowing providers and users to view a reliable history of financial insights and data access.
          </p>
          <p className={styles.p}>
            Key components of our approach include:
          </p>
          <ul className={styles.ul}>
            <li>Blockchain-based transparent usage tracking to monitor how content is accessed and used</li>
            <li>Smart contracts that automate equitable revenue-sharing based on content engagement</li>
            <li>Decentralized, timestamped storage to preserve the historical context of financial information</li>
          </ul>
        </section>


        <section id="research" className={styles.section}>
          <h2 className={styles.h2}>Latest Research</h2>
          <div className={styles.articleContainer}>
            <img src={GraphImage} alt="Flow Engineering Boosting LLM Performance" className={styles.articleImage} />
            <div className={styles.articleContent}>
              <h3 className={styles.h3}>
                <a href="https://datacortex.notion.site/Flow-Engineering-Boosts-LLMs-Performance-with-LATS-9dcb05de1bea4d8dbc0b696e42fb9b0c" className={styles.titleLink}>
                  Paper Discussion: How Flow Engineering Boosts LLM Performance with LATS
                </a>
              </h3>
              <p className={styles.p}>Using Flow Engineering and LATS, GPT-4's task success rate improved from 67% to 94.4% on the HumanEval benchmark.</p>
              <div className={styles.authorInfo}>
                <img src={AuthorImage} alt="Alex Nesovic" className={styles.authorImage} />
                <span>Alex Nesovic</span>
                <span className={styles.date}>4th July 2024</span>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
