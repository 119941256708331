// src/components/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, canonical }) => {
  const defaultTitle = 'Infocom - Orchestrating Intelligence';
  const defaultDescription = 'Discover a new way of finding information with Infocom\'s advanced exploratory research models. We\'re shifting from traditional search to innovative exploration engines.';
  const defaultKeywords = 'Infocom, Info-com.io, Info-com, web research, exploratory research, vector databases, LLMs, context-based research';

  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      {canonical && <link rel="canonical" href={canonical} />}
      
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:type" content="website" />
      {canonical && <meta property="og:url" content={canonical} />}
      
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
    </Helmet>
  );
};

export default SEO;