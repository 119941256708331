import React from 'react';
import './css/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        &copy; {new Date().getFullYear()} Info-com.io is Infocom's property. All rights reserved.<br/>
        Incorporated in the Grand Duchy of Luxembourg, Europe, RCS Registration: B279794.
      </div>
    </footer>
  );
};

export default Footer;