// Home.js
import React from 'react';
import './Home.css';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../Components/SEO';

function Home() {
  const pageVariants = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const contentVariants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <SEO 
        title="Info-com.io: AI and Blockchain-Powered Search Engines"
        description="Explore immutable, AI-driven financial insights. Financial-Portfolio.io offers historical snapshots and investment analysis, leveraging blockchain for trustworthy and timeless data."
        keywords="financial insights, blockchain, AI-driven analysis, historical data, investment platform"
        canonical="https://financial-portfolio.io/"
      />
      <motion.main 
        className="main_div_home"
        initial="initial"
        animate="animate"
        variants={pageVariants}
      >
        <Container className="content-container">
          <motion.div variants={contentVariants} className="main_container">
            <div className="left_section">
              <motion.div variants={contentVariants}>
                <motion.h1 className="headline" variants={contentVariants}>
                  Orchestrating Intelligence
                </motion.h1>
                <motion.div variants={contentVariants}>
                  <Link 
                    to="/products" 
                    className="custom-btn"
                    component={motion.a}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Explore Our Technology
                  </Link>
                </motion.div>
              </motion.div>
            </div>
            <div className="right_section">
              {/* Right section content if needed */}
            </div>
          </motion.div>
        </Container>
      </motion.main>
    </>
  );
}

export default Home;