// src/Components/Header.jsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/navbar.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Logo from './white_logo.svg'; 
import { Link, useLocation } from 'react-router-dom'; 

function Header() {
  const location = useLocation();
  // Add products to the dark navbar pages
  const isDarkNavbar = ['/', '/products', '/contact'].includes(location.pathname);

  return (
<Navbar expand="lg" className={`navbar-custom ${isDarkNavbar ? '' : 'navbar-inverted'}`}>
      <Container className="navbar-container">
        <Navbar.Brand as={Link} to="/" className="navbar-brand-custom">
          <img src={Logo} alt="Logo" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="nav-links-custom">
            <Nav.Link as={Link} to="/" className="nav-link-custom">Home</Nav.Link>
            {/* <Nav.Link as={Link} to="/about" className="nav-link-custom">About</Nav.Link> */}
            <Nav.Link as={Link} to="/products" className="nav-link-custom">Products</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-link-custom">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;