// Contact.js
import React from 'react';
import './Contact.css';
import linkedInLogo from './linkedin-logo.png';

export default function Contact() {
  return (
    <div className="dark-bg-page">
      <section className="contact-section">
        <div className="contact-content">
          <div className="contact-text">hello@info-com.io</div>
          <a href="https://www.linkedin.com/company/infocom-engines/" 
             target="_blank" 
             rel="noopener noreferrer" 
             className="linkedin-link">
            <img src={linkedInLogo} alt="LinkedIn" className="linkedin-logo" />
          </a>
        </div>
      </section>
    </div>
  );
}